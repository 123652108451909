<template>
  <div class="bg">
    <div class="qr-bg"></div>
    <qrcode-stream @decode="onDecode" @init="onInit" style="height: 100vh" />
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { Dialog } from "vant";
export default {
  components: { QrcodeStream },

  data() {
    return {
      result: "",
      error: "",
    };
  },

  methods: {
    onDecode(result) {
      if (result) {
        this.$router.push({ path: "/trainManage/item", query: { id: result } });
      }
    },
    async onInit(promise) {
      let _self = this;
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "你需要提供摄像头权限";
        } else if (error.name === "NotFoundError") {
          this.error = "您的设备上没有摄像头";
        } else if (error.name === "NotSupportedError") {
          this.error = "需要安全网络连接";
        } else if (error.name === "NotReadableError") {
          this.error = "摄像头可能正在被使用";
        } else if (error.name === "OverconstrainedError") {
          this.error = "摄像头不兼容";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "扫码功能不支持该浏览器";
        } else if (error.name === "InsecureContextError") {
          this.error = "摄像头权限只可以在安全网络连接下开始";
        } else {
          this.error = `摄像头错误 (${error.name})`;
        }
        Dialog.alert({
          message: this.error,
        }).then(() => {
          _self.$router.push("/home");
        });
      }
    },
  },
};
</script>

<style scoped>
.error {
  font-weight: bold;
  color: red;
}
.bg {
  min-height: 100vh;
  fullscreen: true;
  background: #000;
}
.qr-bg {
  background-image: url("../../assets/images/qr-bg.png");
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 999;
}
</style>
